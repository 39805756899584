import React from 'react';

import StandardPage from '../components/StandardPage';
import Head from '../components/Head';

export default () => (
  <StandardPage>
    <Head
      title="Privacy Policy"
      description="This Privacy Policy describes the policies and procedures of Bagel Health Inc. relating to data security and integrity."
      url="/privacy/"
      image="/og_image.jpg"
    />
    <div className="section col ac jc bl60 pmargin sd">
      <div className="readable">
        <h2 className="title">Privacy Policy</h2>
        <h4 className="title">Bagel Health, Inc.</h4>
        <br />
        <p>
          This Privacy Policy describes the policies and procedures of Bagel Health Inc. and any
          subsidiaries and affiliated entities (together, “Company”, “we” or “us”) with respect to
          the collection, use, disclosure and protection of personal and other information received
          or generated by Company when you use or interact with the applications, services, products
          and websites (collectively, the “Services”) offered or made available by Company,
          including but not limited to its online service for direct-pay, medical care providers and
          their members or patients. By using or accessing the Services, or by submitting
          information to Company, you consent to this Privacy Policy.
        </p>
        <p>
          This Privacy Policy is to be read in conjunction with the{' '}
          <a href="/baa">Business Associate Agreement</a> between You and Company. To the extent the
          Privacy Policy conflicts with the Business Associate Agreement, the terms of the Business
          Associate Agreement control.
        </p>
        <p>
          Company may modify or amend this Privacy Policy in its sole discretion from time to time
          by posting the modifications or the amended Privacy Policy on the Site and providing you
          with written notice thereof. All modified terms and conditions and amended Privacy Policy
          will be effective thirty (30) days after such notice (unless a longer notice period is
          required by applicable law), unless You first give Company written notice of rejection of
          the modifications or amended Privacy Policy within such thirty day period. Your continued
          use of the Services after thirty (30) days without written notice of rejection to Company
          constitutes your acceptance of such changes to this Privacy Policy. As used in this
          policy, the terms "using" and "processing" information include subjecting the information
          to statistical or other analysis and using or handling information in any way, including,
          but not limited to collecting, storing, evaluating, modifying, deleting, using, combining,
          disclosing and transferring information within our organization or among our affiliates
          within the United States or internationally and using cookies on a computer. Where
          applicable, for health care providers and similar businesses using the Services,
          references to “you” and “your information” also include your members, customers and
          patients, and their information, if the relevant Services are designed to receive or
          utilize such information of such members, customers and patients.
        </p>
        <h5 className="mt32">Security and protection of your information</h5>
        <p>
          Bagel Health is very concerned with safeguarding your information and protecting the
          security of your Personal Information. We use appropriate, HIPAA-compliant,
          industry-standard <a href="/security">security measures</a> and technology to protect
          against unauthorized access to, or unauthorized alteration, disclosure or destruction of
          user information covered by this Privacy Policy (excluding any such data that may be
          disclosed or publicly shared in accordance with this Privacy Policy). In this regard, we
          employ administrative, physical and electronic measures (including certain encryption
          technologies) designed to protect your personal information from unauthorized access and
          disclosure and to safeguard your information against loss, theft, alteration and
          destruction. No method of security is 100% secure. In addition, some of your information
          may be protected by passwords selected and maintained by you. Accordingly, the security of
          such information will depend upon the strength of your selected password and your ability
          to keep such password secured and confidential.
        </p>
        <p>
          We will make any legally required disclosures of any breach of the security,
          confidentiality, or integrity of your electronically stored "personal data" (as defined in
          applicable statutes on security breach notification) to your email or in writing in an
          expedient manner and without unreasonable delay, insofar as these are consistent with (i)
          the legitimate needs of law enforcement or (ii) any measures necessary to determine the
          scope of the breach and restore the reasonable integrity of the data system.
        </p>
        <h5 className="mt32">What information do we collect?</h5>
        <p>We collect, receive, generate and store the following types of information:</p>
        <ol className="ml32">
          <li>
            <b>User Content</b>: User Content is information, data, text, files, software, graphics,
            music, audio, photographs, video, messages, communications, contacts or other materials,
            that you upload, submit, post, transmit, share or otherwise provide on, through or to
            the Services. This could occur directly or indirectly, such as through your
            authorization of third party sources to release or transmit User Content (and Personal
            Information) into the Services. For example, we receive and store User Content you
            provide to Company when you:
            <ol type="a" className="ml32">
              <li>use the Services; and/or</li>
              <li>
                authorize care providers or other health entities to transmit or upload patient care
                information or health history and background into the Services; and/or
              </li>
              <li>
                post, upload, submit or otherwise provide content, materials or other information
                on, through or to the Services; and/or
              </li>
              <li>
                fill out surveys or questionnaires or sign up for or enter promotions, events or
                other special activities on, through or to the Services; and/or
              </li>
              <li>
                communicate with Company or with other users or participants of the Services
                (including the content of such communications and information provided in connection
                with such communications); and/or
              </li>
              <li>
                provide information to third parties and their web sites, applications and services
                which are accessed or used by Company or on, through or to the Services.
              </li>
            </ol>
          </li>
          <li>
            <b>Information we collect or generate about you and/or your use of Services</b>: We
            receive, collect, generate and store certain types of information whenever you use the
            Services or otherwise interact with us. We may do this directly or through third party
            tools, services, stores, accounts or hosting platforms involved with or connected to the
            Services (including without limitation analytics tools and ad serving technologies). For
            example, we (and such third parties) may automatically record your use of and activity
            on the Services (such as amount, type and manner of use and interaction; time and date
            of your activities; type and amount of User Content submitted to the Services and other
            metrics). We (and such third parties) may also collect information about your device or
            computer on which you use the Services, such as your unique device identifiers, device
            type, model and version, Android or Apple ID, coarse location, fine location, GPS
            coordinates, source and status of network connection, internet service provider, IP
            address, browser type, operating system or other software used, page views, domains and
            similar information. Also, we may automatically send and receive information to and from
            your device or computer in connection with your use of the Services. Like many websites
            and online applications and services, we may also use "cookies" (see below), log files,
            web beacon technologies and other automated tools to obtain certain types of information
            when your web browser or other application accesses our Services.
          </li>
        </ol>
        <h5 className="mt32">How is your information used?</h5>
        <p>
          Company may use the information submitted, collected, received or generated from and/or
          about you for any of the following purposes:
        </p>
        <ol className="ml32">
          <li>
            To operate, provide, administer, develop, and improve the Services and to operate and
            support Company’s related businesses.
          </li>
          <li>
            To better understand how users access and use the Services on an aggregated and
            individualized basis, to track and monitor usage, to conduct quality control, to fix
            technical and other errors or problems, to conduct market research and to respond to
            user desires and preferences.
          </li>
          <li>
            To analyze, compile, publish, sell and otherwise similarly utilize information (other
            than Personal Information/PHI) relating to usage of the Services.
          </li>
          <li>
            For advertising, marketing and promotional purposes by or for the Company (but not to
            provide Personal Information/PHI to third parties for advertising by such third parties
            unrelated to the Company or the Services).
          </li>
          <li>
            To provide personalized experiences and recommendations, language and location
            customization, personalized help and instructions, or other responses to your usage of
            our Services.
          </li>
          <li>
            To track memberships, purchases and usage as necessary for the purpose of our complying
            with any third party agreements or obligations (such as, for example, making royalty or
            other payments to third parties).
          </li>
          <li>To provide you with support and handle inquiries.</li>
          <li>
            To communicate with or contact you concerning your account or membership or your usage
            of or participation in the Services, and for other customer service, This may include
            use of your e-mail address to send you messages and notices for the purposes described
            above and below.
          </li>
          <li>
            If applicable, to bill you, process payments and for authorization, account history and
            billing purposes. This may include credit card authorization and verification for
            purchases made from Company, location verification for products and services which may
            have limited geographical access, and the like.
          </li>
          <li>
            To offer you content, services, or other products and services, including to develop new
            services and products.
          </li>
          <li>
            To provide you with news and newsletters, special offers, promotions, and targeted
            advertising.
          </li>
          <li>
            To comply with applicable laws, rules and regulations and any regulatory mandate or
            court order.
          </li>
          <li>
            To enforce our Terms of Service and other applicable agreements, rights and remedies.
          </li>
          <li>
            To protect the safety of any person, to address fraud, security or technical issues, or
            to protect Company’s rights or property.
          </li>
        </ol>
        <p>
          Company does not rent, sell or publicize your Personal Information/PHI without your prior
          consent. Use of personally identifiable health information is further subject to the
          restrictions described in more detail below.
        </p>
        <h5 className="mt32">When is information shared or disclosed?</h5>
        <p>
          Information provided by our users is an important part of our business. Company will share
          and disclose information submitted, collected, received or generated from and/or about you
          only as compatible with the purposes described above (except for certain personal health
          information which shall be kept confidential as described below), when we have your
          consent (as may be provided by you from time to time through the Services or otherwise),
          and as described in this section as follows:
        </p>
        <ul className="ml32">
          <p>
            The Services may include or interoperate with applications, services, features or
            functionalities provided by third parties. In connection with such third party
            applications, services, features or functionalities, you may send (or such third parties
            may get access to) certain information about or related to you or your activities
            therewith. The use, storage and disclosure of such information by these third parties is
            subject to their own privacy policies and Company has no responsibility or liability for
            such third parties’ acts or omissions or the information provided to them.The Services
            may include or interoperate with applications, services, features or functionalities
            provided by third parties. In connection with such third party applications, services,
            features or functionalities, you may send (or such third parties may get access to)
            certain information about or related to you or your activities therewith. The use,
            storage and disclosure of such information by these third parties is subject to their
            own privacy policies and Company has no responsibility or liability for such third
            parties’ acts or omissions or the information provided to them.
          </p>
          <li>
            Public Forum: Any User Content or other user information, content or materials
            submitted, posted, shared, transmitted or otherwise provided to public portions of the
            Services (such as video chat rooms, discussion groups, bulletin boards and similar
            forums) or portions of the Services designed for broad user access (such as reviews,
            focused user groups, etc…) are or may be shared with the public without restriction.
            Company also reserves the right to publish or make publicly available any information
            that is already publicly available prior to the time first provided to or collected by
            Company or information that becomes publicly available without any action or omission on
            the part of Company.
          </li>

          <li>
            Aggregated Data and Anonymous Data: Company may publish and otherwise disclose (a) on an
            aggregated basis, information relating to usage and users of the Services, and (b)
            information that is de-identified and otherwise free of any information that would allow
            you to be identified.
          </li>

          <li>
            Disclosures In Connection with Permitted Uses: Company may disclose your information to
            third parties (including without limitation to publishers and business partners) in
            connection with the purposes described in the previous section such as, for example, to
            implement advertising, marketing, publishing or promotional activities or transactions,
            or to publish, sell and otherwise exploit information (other than Personal
            Information/PHI) relating to usage and users of the Services.
          </li>

          <li>
            Company Affiliates: We may share your information with our subsidiaries and other
            affiliates, but only if those entities are either subject to this Privacy Policy or
            follow practices at least as protective as those described in this Privacy Policy.
          </li>

          <li>
            Contractors, Collaborators and Agents: We may employ other companies and individuals to
            perform functions or otherwise act on our behalf. Examples include processing payments,
            resolving service problems, correcting errors related to the execution of the Services,
            sending postal mail and e-mail, removing repetitive information from customer lists,
            analyzing data, providing marketing assistance (including data concerning marketing and
            promotional programs), hosting websites or software, developing websites and software,
            providing customer service, assisting Company with providing, administering, developing
            or improving the Services, and/or for other purposes within the ordinary course of
            business. All contractors, collaborators and agents agree to abide by same
            confidentiality obligations as Company, including associated heightened privacy
            requirements in the Business Associate Agreement where applicable and as mandated by
            law.
          </li>

          <li>
            Business Transfers and Collaborations: As we continue to develop our business, we might
            sell the Company and/or its business or assets and/or sell or buy online sites,
            services, subsidiaries, or other businesses; or we might collaborate or partner with
            other companies in strategic transactions, publishing or distribution arrangements,
            business arrangements or licenses. In such transactions, customer and user information
            and information regarding usage of the Services generally would be one of the
            transferred business assets or otherwise one of the components of, or involved in, the
            transaction, we may therefore share this information in connection with such
            transactions or arrangements.
          </li>

          <li>
            Compliance with Law and Protection of Company and Others: We reserve the right to
            disclose your Personal Information and any other information when we believe release is
            appropriate to comply with the law, judicial proceeding, court order, subpoena or other
            legal process provided that we provide you reasonable notice of the order, subpoena or
            request to allow you to seek a protective order, if necessary; detect, prevent, or
            otherwise address fraud, security, emergency situations or technical issues; enforce or
            apply our Terms of Service and other agreements, rights and remedies; or protect the
            rights, property, or safety of Company, our employees and contractors, our users, or
            others. This includes exchanging information with other companies and organizations for
            fraud protection and credit risk reduction purposes.
          </li>
        </ul>
        <p>
          The Services may include or interoperate with applications, services, features or
          functionalities provided by third parties. In connection with such third party
          applications, services, features or functionalities, you may send (or such third parties
          may get access to) certain information about or related to you or your activities
          therewith. The use, storage and disclosure of such information by these third parties is
          subject to their own privacy policies and Company has no responsibility or liability for
          such third parties’ acts or omissions or the information provided to them.
        </p>
        <h5 className="mt32">Confidentiality and security of health information</h5>
        <p>
          Some of our users – such as healthcare providers – are subject to laws and regulations
          governing the use and disclosure of health information they create or receive. Included
          among them is the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”),
          the Health Information Technology for Economic and Clinical Health of 2009 (“HITECH”), and
          the regulations adopted thereunder. When we store, process or transmit certain
          individually identifiable health information or “Protected Health Information” (as such
          term is defined by HIPAA) on behalf of a health care provider, we do so as its “business
          associate” (as also defined by HIPAA) under a Business Associate Agreement (bagel.md/baa).
          Under this agreement, we are prohibited from, among other things, using Protected Health
          Information in a manner that the provider itself may not. We are also required to, among
          other things, apply reasonable and appropriate measures to safeguard the confidentiality,
          integrity and availability of Protected Health Information we store and process on behalf
          of such providers. We are also subject to laws and regulations governing the use and
          disclosure of certain personal and health information, including HIPAA, when we operate as
          a business associate of a healthcare provider.
        </p>
        <h5 className="mt32">Changing or deleting your information; storage and retention</h5>
        <p>
          Except as may be inherent in the features and functionality of the Services, Company does
          not have a mechanism for you to review, edit or delete all of your information on or
          stored in the Services or otherwise by Company. If in the future, the Company may
          implement a system to allow for a complete review and deletion of all you information, but
          Company has no obligation to provide or create such a mechanism.
        </p>
        <p>
          If you would like us to delete your Personal Information in our system, please contact us
          at team@bagel.md and we will accommodate your request (but will not do so if we have any
          legal obligation to retain the record). In such cases, we ask individual users to identify
          themselves and the information requested to be accessed, corrected or removed before
          processing such requests. If Company is unable to destroy or delete the information due to
          legal obligations or because it is not feasible or because it is unduly burdensome or
          impractical or would jeopardize the privacy of others or the integrity of the Services,
          Company is not obligated to do so, but Company shall provide a written response stating
          the reason(s) it cannot fulfill the request. Because of the way we maintain certain
          Services, after you delete your information, residual copies may take a period of time
          before they are deleted from our active servers and may remain in our backup systems. We
          are not responsible for updating, correcting or removing any of your information disclosed
          to or held by third parties.
        </p>
        <p>
          Please be aware the Company is under no obligation to maintain and store any of your
          information (including any Personal Information or User Content) or otherwise to maintain
          and operate the Services. The Company reserves the right to cease or modify operation of
          the Services at any time – in such case, your information (including any Personal
          Information and User Content) may no longer be available. If you desire to maintain access
          to any such information, you are encouraged to keep a backup of such information in other
          locations.
        </p>
        <h5 className="mt32">Cookies and other files</h5>
        <p>
          Like many services, websites or applications, we may use "cookies", pixel tags, locally
          shared objects, web beacons or similar technologies to collect information. A cookie is a
          small data file that we transfer to your computer's or device’s memory for record-keeping
          purposes. We may use cookies or such other technologies to improve the quality of the
          Services, including for storing user preferences and tracking user trends and for any of
          the other purposes permitted in this Privacy Policy. We may utilize persistent cookies to
          save your registration/member ID and login password for future logins to the Services. We
          may utilize session ID cookies to enable certain features of the Services, to better
          understand how you interact with the Services and to monitor aggregate usage by Company
          users and traffic routing on the Services. Unlike persistent cookies, session cookies are
          usually deleted from your computer or device when you log off from the Services and then
          close your browser or application. Third party advertisers on the Services may also place
          or read cookies on your browser or within the Services or other application.
        </p>
        <p>
          Log file information may be automatically reported by your browser or smartphone
          application each time you access a web page, app or other electronic location. When you
          use the Services, our servers may record certain information that your web browser, the
          Services or other application sends whenever you utilize the Services, visit any website
          or utilize any other services or applications.
        </p>
        <h5 className="mt32">International transfer</h5>
        <p>
          Our Services are hosted in the United States. If you use the Services from the European
          Union, Asia or any other country outside the United States with laws or regulations
          governing personal data collection, use and disclosure that differ from the United States
          laws and regulations, please be advised that through your continued use of the Services,
          you are transferring your information to the United States and you consent to that
          transfer. Your information may be transferred to - and maintained on - computers located
          outside of your state, province, country or other governmental jurisdiction where the
          privacy laws may not be as protective as those in your jurisdiction. If you are located
          outside the United States and choose to provide information to us or use the Services,
          Company transfers personal information to the United States and processes it there, and
          your submission of such information represents your consent and agreement to that
          transfer.
        </p>
        <h5 className="mt32">Children's privacy</h5>
        <p>
          In order to use our Services you must represent and warrant that you are over the age of
          13 before using, downloading or accessing the Services. Children under the age of 13 are
          not eligible to use the Services and must not attempt to download the Services, register
          with the Company and/or submit any personal information to us. We do not knowingly collect
          personal information from any person who is under the age of 13 or allow them to register.
          If it comes to our attention that we have collected personal data from a person under the
          age of 13, we will delete this information as quickly as possible.
        </p>
        <p>
          Without limiting the generality of the foregoing, our Services do allow users above the
          age of 18 years old – such as care providers, parents and guardians – to submit personal
          information about others, including minors. Such users assume full responsibility over
          their submission, use and transmission of such information.
        </p>
        <h5 className="mt32">Third party sites, services, and applications</h5>
        <p>
          Our Services may contain links to, interoperate with, and allow you to share content to
          and from third party services, websites and applications. The fact that we link to a
          website, service or application or allow you to share content through these third parties
          is not an endorsement, authorization or representation that we are affiliated with that
          third party, nor is it an endorsement of their privacy or information security policies or
          practices. Other websites, services and applications follow different rules regarding the
          collection, use, storage or disclosure of the personal and other information. We encourage
          you to read the privacy policies or statements of the other websites, services and
          applications you use.
        </p>
        <h5 className="mt32">Limitation of liability, disclaimer of warranties</h5>
        <p>
          Even though Company has taken reasonable commercial steps and efforts to prevent your
          Personal Information and other information covered by this Privacy Policy from being
          accessed, used, intercepted or disclosed by unauthorized individuals in violation of this
          Privacy Policy, you should know and you acknowledge that Company cannot fully eliminate
          security risks associated with your information. You expressly acknowledge and agree that
          uploading, posting, providing, storing, using, analyzing, transmitting, sharing and/or
          allowing access to your Personal Information and other information on, through, in or to
          the Services, and the use of all such Services, are all done at your sole risk and
          responsibility. You expressly acknowledge that Company and its employees, agents,
          contractors, collaborators, publishers and business partners are not liable for (i) any
          special, indirect, consequential, incidental or punitive damages, costs, or liabilities
          whatsoever arising out of or resulting from your use of the Services, including from your
          uploading, posting, providing, storing, using, analyzing, transmitting, sharing and/or
          allowing access to your Personal Information and other information; or (ii) any loss,
          disclosure or use of your Personal Information or other information.
        </p>
        <p>
          Unless expressly provided otherwise in writing by the Company and the Services are
          provided “AS IS” without warranty of any kind. The Company makes no, and hereby disclaims
          any and all, representations and warranties of any kind, express or implied, with respect
          to the Services, including without limitation, warranties of merchantability, fitness for
          a particular purpose and non-infringement.
        </p>
        <p className="small bl40 mt32">Updated 6 December, 2018</p>
      </div>
    </div>
  </StandardPage>
);
